import React from "react";
import classNames from "classnames";
import NewsFeaturesSplit from "./../../views/Newsroom/NewsFeatureSplit";
import "semantic-ui-css/semantic.min.css";
const Newsroom = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const innerClassesTop = classNames(
    "section-inner center-content image-top image-newsroom",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );
  const innerClasses = classNames(
    "center-content container-top-box container-xsm clip-folder",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section>
      <div className={innerClassesTop}>
        <div className={innerClasses}>
          <h1 className="reveal-from-bottom" data-reveal-delay="200">
            <span className="text-color-primary center-content">newsroom</span>
          </h1>
          <div className="ta-j reveal-from-bottom" data-reveal-delay="400">
            <span className="text-color-high">
              here we provide you with the latest space news about
              <span className="text-color-primary"> thya aerospace</span>,
              covering our projects and major milestones.
            </span>
          </div>
        </div>
      </div>
      <div className={({ innerClasses }, "reveal-rotate-from-left")}>
        <NewsFeaturesSplit />
      </div>
    </section>
  );
};

export default Newsroom;
