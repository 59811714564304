import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const innerClasses = classNames(
    "features-split-inner container",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  return (
    <section {...props}>
      <div className={innerClasses}>
        <div className={splitClasses}>
          <div className="split-item">
            <div
              className="split-item-content center-content-mobile reveal-from-left"
              data-reveal-container=".split-item"
            >
              <div className="text-color-primary">our access to space</div>
              <h3 className="mt-0 mb-12">
                <a href="/Projects">thya's space projects</a>
              </h3>
              <p className="m-0">
                we are active in different space projects, mainly as software
                system developers. our expertise covers all space segments, but
                with a focus on ground. for details what we do, please visit our
                projects page.
              </p>
              <a href="/Projects">our projects</a>
            </div>
            <div
              className={classNames(
                "split-item-image center-content-mobile reveal-from-bottom",
                imageFill && "split-item-image-fill"
              )}
              data-reveal-container=".split-item"
            >
              <Image
                className="clip-leftagon"
                src={require("./../../assets/images/project_images/issorbit.jpg")}
              />
            </div>
          </div>

          <div className="split-item">
            <div
              className="split-item-content center-content-mobile reveal-from-right"
              data-reveal-container=".split-item"
            >
              <div className="text-color-primary">
                Considering a career in space?
              </div>
              <h3 className="mt-0 mb-12">
                <a href="/Jobs">Jobs at thya</a>
              </h3>
              <p className="m-0 ">
                at thya aerospace we search highly motivated space enthusiasts
                who want to shape the future of European space and beyond. Check
                our open positions. In case you cannot find an open position
                that suits you, make an initiative application.
              </p>
              <a href="/Jobs">open positions</a>
            </div>
            <div
              className={classNames(
                "split-item-image center-content-mobile reveal-from-bottom",
                imageFill && "split-item-image-fill"
              )}
              data-reveal-container=".split-item"
            >
              <Image
                className="clip-rightagon"
                src={require("./../../assets/images/jobs_images/office_5.jpg")}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
