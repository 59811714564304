import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/Logo";
import FooterNav from "./partials/FooterNav";
import FooterSocial from "./partials/FooterSocial";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const showInMapClicked = () => {
  window.open(
    "https://goo.gl/maps/iBSy1J9bfs29js1b8",
    "map",
    "width=1000,height=1000"
  );
};

/* const showMapPreview = ()=>{
  return (<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1332.218708274291!2d11.538568558346176!3d48.101797144805126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ddf2bc2924bcd%3A0x9af643c293e80b82!2sPlinganserstra%C3%9Fe%20150%2C%2081369%20M%C3%BCnchen!5e0!3m2!1sde!2sde!4v1613255459278!5m2!1sde!2sde" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>);
};
 */
const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    "site-footer footer-nav center-content-mobile",
    topOuterDivider && "has-top-divider",
    className
  );

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "site-footer-inner",
            topDivider && "has-top-divider"
          )}
        >
          <div className="footer-bottom space-between">
            <Logo />
          </div>
          <div className="footer-bottom space-between text-sm text-color-high">
            <a className="footer-address" onClick={showInMapClicked}>
              thya aerospace gmbh <br />
              plinganserstr. 150 <br />
              81369 Munich Germany
            </a>

            <div>
              <FooterSocial />
              <FooterNav />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
