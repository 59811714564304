import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const ProjectTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "projecttiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const tilesClasses = classNames("tiles-wrap iss-bg", pushLeft && "push-left");

  return (
    <section {...props} className={outerClasses}>
      <div className={tilesClasses}>
        <div className="tiles-item reveal-from-right " data-reveal-delay="100">
          <div className="tiles-item-inner">
            <Link to="/Projects/PPP">
              <Image
                src={require("./../../assets/images/header_images/precision.JPG")}
                className="image-projects-tile"
              />
            </Link>
            <NavLink to={`/Projects/PPP`}>
              <div className="text-sm text-color-primary">
                High Accuracy Correction Service
              </div>
            </NavLink>
            <NavLink to={`/Projects/PPP`}>
              <h2 className="pt-16">Precise <br /> Point  <br />Positioning </h2>
            </NavLink>
            <p className="ta-j pt-16">
              Precise Point Positioning (PPP) services provide real-time orbit and clock corrections as
              well as code and phase biases to achieve 20-centimeter level and better  GNSS
              position accuracy for the user. This uprising technology and
              world-wide available system enables a magnitude of applications,
              such as autonomous navigation, sensor geo-referencing and
              scientific geodesy.
            </p>
          </div>
        </div>
        <div className="tiles-item reveal-from-right " data-reveal-delay="100">
          <div className="tiles-item-inner">
            <Link to="/Projects/Satsimulator">
              <Image
                src={require("./../../assets/images/header_images/simulator_facility.png")}
                className="image-projects-tile"
              />
            </Link>
            <NavLink to={`/Projects/Satsimulator`}>
              <div className="text-sm text-color-primary">
                simulation for dev-ops
              </div>
            </NavLink>
            <NavLink to={`/Projects/Satsimulator`}>
              <h2 className="pt-16"> space <br />system simulator </h2>
            </NavLink>
            <p className="ta-j pt-16">
              At thya aerospace we work at the development of an integrated high fidelity space system simulator. Those simulators are a cornerstone for a successful space mission. They are an enabling technology for satellite system development, for assembly, integration and testing as well as for safe operation of the space segment in orbit. we further develop this key technology, integrating it in a modern development lifecycle.

            </p>
          </div>
        </div>
      </div>
    </section >
  );
};

ProjectTiles.propTypes = propTypes;
ProjectTiles.defaultProps = defaultProps;

export default ProjectTiles;
