import React from "react";
import classNames from "classnames";
import Table from "react-bootstrap/Table";
import FeaturesTilesJobs from "../components/sections/FeaturesTilesJobs";
import { jobsData } from "./../components/data/JobsOpening";

const Jobs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
}) => {
  const innerClassesTop = classNames(
    "section-inner center-content image-top image-jobs ",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );
  const innerClasses = classNames(
    "section-inner center-content clip-folder",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section>
      <div className={innerClassesTop}>
        {/*     <h2 className="bottom-right text-color-high">
          ready for new challenges in space?
        </h2> */}
      </div>
      <div className={innerClasses}>
        <h2 className="reveal-from-bottom" data-reveal-delay="200">
          <span className="text-color-low">
            ready for new challenges in space?
            <br />
            <br />
          </span>
        </h2>
        <div className="reveal-from-bottom container-sm ta-j">
          <span className="text-color-primary">thya aerospace </span>
          <span className="text-color-high">
            is searching for highly motivated talents ready to tackle
            challenging space projects that will reshape the way we master and
            explore space today and in future. we are a small German space
            technology start-up from Munich, integrated by staff from the German
            Aerospace Center (DLR). located in a brand new office, over the
            roofs of Munich. we are an international entity, an equal
            opportunity employer and offering competitive salaries. currently
            thya aerospace is in the initial phase of the venture. for us, these
            days are a very dynamic time, letting us shoot out of the dark of a
            global crisis
          </span>
        </div>
      </div>
      <div className="container-sm center-content">
        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
          <span className="text-color-primary">open positions</span>
        </h1>

        <Table responsive borderless clip-leftagon>
          <thead className="text-color-high">
            <tr>
              <th>job title</th>
              <th>location</th>
              <th>type</th>
            </tr>
          </thead>

          {jobsData.map((data, key) => {
            return (
              <tbody align="left" key={key}>
                <tr className="text-color-high">
                  <td>
                    <a className="fw-700" href={data.link}>
                      {data.jobTitle}
                    </a>
                  </td>
                  <td>{data.location}</td>
                  <td>{data.employmentType}</td>
                </tr>
              </tbody>
            );
          })}
        </Table>

        <div className={innerClasses}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            <span className="text-color-primary">what to expect</span>
          </h1>
          <FeaturesTilesJobs />
        </div>
      </div>
    </section>
  );
};

export default Jobs;
