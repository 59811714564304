import React from "react";
import classNames from "classnames";
import Table from "react-bootstrap/Table";

const LegalNotice = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
}) => {
  const outerClasses = classNames(
    "center-content section-inner",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );
  const bottomClasses = classNames(
    "section-middle",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section className={outerClasses}>
      <div className={innerClasses}>
        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
          <span className="text-color-primary">disclaimer</span>
        </h1>
        <div className="container-xs">
          <p
            className="m-0 mb-32 reveal-from-bottom ta-j"
            data-reveal-delay="400"
          >
            <h4 className="mt-0 mb-16 center-content" data-reveal-delay="200">
              <br />
              1. Content
            </h4>
            The content of our website has been compiled with meticulous care
            and to the best of our knowledge. However, we cannot assume any
            liability for the up-to-dateness, completeness or accuracy of any of
            the pages. Pursuant to section 7, para. 1 of the TMG
            (Telemediengesetz – Tele Media Act by German law), we as service
            providers are liable for our own content on these pages in
            accordance with general laws. However, pursuant to sections 8 to 10
            of the TMG, we as service providers are not under obligation to
            monitor external information provided or stored on our website. Once
            we have become aware of a specific infringement of the law, we will
            immediately remove the content in question. Any liability concerning
            this matter can only be assumed from the point in time at which the
            infringement becomes known to us.
            <h4 className="mt-0 mb-16  center-content" data-reveal-delay="200">
              <br />
              2. External links
            </h4>
            Our website contains links to the websites of third parties
            („external links“). As the content of these websites is not under
            our control, we cannot assume any liability for such external
            content. In all cases, the provider of information of the linked
            websites is liable for the content and accuracy of the information
            provided. At the point in time when the links were placed, no
            infringements of the law were recognisable to us. As soon as an
            infringement of the law becomes known to us, we will immediately
            remove the link in question.
            <h4 className="mt-0 mb-16  center-content" data-reveal-delay="200">
              <br />
              3. Copyright
            </h4>
            The content and works published on this website are governed by the
            copyright laws of Germany. Any duplication, processing, distribution
            or any form of utilisation beyond the scope of copyright law shall
            require the prior written consent of the author or authors in
            question. The author intended not to use any copyrighted material
            for the publication or, if not possible, to indicate the copyright
            of the respective object.
            <h4 className="mt-0 mb-16  center-content " data-reveal-delay="200">
              <br />
              4. Copyright indication
            </h4>
            The subsequent list collects image material which is not owned by
            thya aerospace but by external authors, which agreed to publish
            their work on this webpage.
          </p>
        </div>
      </div>

      <div className="container-xs table-content">
        <Table responsive striped borderless table-hover>
          <thead className="text-color-high">
            <tr>
              <th>Item</th>
              <th>Author</th>
              <th>Link</th>
            </tr>
          </thead>

          <tbody align="left" className="text-color-high">
            <tr>
              <td>All Mars surface images</td>
              <td>NASA</td>
              <td><a href="https://www.nasa.gov/multimedia/imagegallery/index.html">
                <strong>NASA image gallery</strong>
              </a></td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className={bottomClasses}>
        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
          <span className="text-color-primary">legal notice</span>
        </h1>
        <div className="container-xs">
          <p
            className="m-0 mb-32 reveal-from-bottom"
            data-reveal-delay="200"
            align="center"
          >
            thya aerospace GmbH <br />
            plinganserstr. 150 <br />
            81369 munich / germany <br />
            <br />
            E-Mail: contact@thya-aerospace.com <br />
            Managing Director: K. Hoeflinger <br />
            <br />
            AG München • HRB 256579 <br />
            VAT ID: DE331830649 <br />
            <br />
            this webpage is powered by <a href="https://cruip.com/">cruip</a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default LegalNotice;
