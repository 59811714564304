import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import Image from "../../components/elements/Image";
import { newsData } from "../../components/data/News";
import { Grid, List } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const NewsFeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const innerClasses = classNames(
    "features-split-inner news-split section-inner container",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  function subStringDescription(str) {
    return str.substring(0, 290);
  }
  function subStringDescriptionMobile(str) {
    return str.substring(0, 80);
  }
  const ArticleItem = (props) => {
    const { article } = props;
    return (
      <List.Item style={{ padding: 30 }}>
        <div id="content-desktop">
          <Grid
            className="newspage-container clip-news"
            style={{ padding: "5px" }}
            doubling
            columns={2}
          >
            <Grid.Column
              className="ta-l"
              width={9}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <h3 className="news-article">{article.title} </h3>
              <List.Description>
                <p className="news-content ta-j">
                  <p className="p-min-height-newsroom">
                    {subStringDescription(article.abstract)}...
                    <NavLink to={`/Newsroom/${article.url}`}>
                      <span className="news-article readmore"> read more</span>
                    </NavLink>
                  </p>
                </p>
              </List.Description>
              <div>
                <List bulleted horizontal>
                  <List.Item>{article.author}</List.Item>
                  <List.Item>{article.publishedAt.split("T")[0]}</List.Item>
                </List>
              </div>
            </Grid.Column>
            <Grid.Column
              width={7}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Image
                className="newspage-icon"
                src={require(`./../../assets/images/news_images/${article.imageUrl}`)}
              />
              <p className="text-xxs ta-r">{article.imageCaption}</p>
            </Grid.Column>
          </Grid>
        </div>
        <div id="content-mobile">
          <Grid className="newspage-container clip-folder" doubling columns={2}>
            <Grid.Column
              className="ta-l"
              width={9}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <NavLink to={`/Newsroom/${article.url}`}>
                <h3 className="news-article">{article.title} </h3>
              </NavLink>
              <div className="pt-0">
                <List bulleted horizontal>
                  <List.Item>
                    <p className="text-xxs"> {article.author}</p>
                  </List.Item>
                  <List.Item>
                    <p className="text-xxs">
                      {" "}
                      {article.publishedAt.split("T")[0]}
                    </p>
                  </List.Item>
                </List>
              </div>
              <Image
                className="newspage-icon"
                src={require(`./../../assets/images/news_images/${article.imageUrl}`)}
              />
              {subStringDescriptionMobile(article.abstract)}...
            </Grid.Column>
          </Grid>
        </div>
      </List.Item>
    );
  };
  const ArticleList = (props) => {
    return (
      <List divided style={{ maxWidth: 1200 }}>
        {newsData.map((data, index) => {
          return (
            <div>
              <ArticleItem article={data} key={index} />
            </div>
          );
        })}
      </List>
    );
  };
  return (
    <section {...props}>
      <div className={innerClasses}>
        <ArticleList />
      </div>
    </section>
  );
};

NewsFeaturesSplit.propTypes = propTypes;
NewsFeaturesSplit.defaultProps = defaultProps;

export default NewsFeaturesSplit;
