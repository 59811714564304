export const newsData = [
  {
    source: {
      id: "N000003",
      name: "Simulation Based SmallSat-EGSE",
    },
    author: "thya",
    title: "Contract signed: Simulation Based SmallSat-EGSE",
    url: "SmallSatEGSE",
    abstract:
      "we have signed a new contract with ESA for SmallSat-EGSE project to develop an extendable, affordable and EGS-CC aligned space system simulator as small form factor core-EGSE for everyone.",
    imageUrl: "smallSatEGSE.png",
    imageCaption: "smallsat-egse facility design",
    publishedAt: "2021-11-22",
    content: [
      {
        contentText:
          "The smallSat-EGSE represents a cost-effective, reusable, and extendable rapid development platform for smallSat missions with a very small form factor. \
          It is a HW/SW co-simulator based on configurable simulator tool EuroSim,  integrated  with  an  EGS-CC-based  central  checkout  system,  running  on  a \
          commercial-grade  server  setup.  It  fosters  rapid  integration  and  testing  of  advanced  hardware  and \
          software solutions for space and aims to lower the space market entry barrier for SMEs and universities.",
      },
      {
        contentText:
          "The smallSat-EGSE shall act as a miniaturized satellite technology development testbed, leveraging EGS-CC’s\
          data handling concepts and data interfaces. The  simulator  of  the  smallSat-EGSE  shall  also  offer  versatile  hardware  interface  cards, \
          integrated via adapter implementations on PCIe. Entities willing to use the \
          smallSat-EGSE shall be able to get the software from ESA, together with a hardware configuration plan \
          so  they  can  purchase  the  needed  hardware  freely  on  the  market  for  their  own  initial  1.0  version  of \
          smallSat-EGSE.",
      },
      {
        contentText:
          "The  smallSat-EGSE  enables  developers, maintainers,  and  operators  to grow their own facilities around the EGS-CC core. \
          There they can validate  their  design,  their  maintenance  change  and  their  operational \
          procedures. Especially the usage of COTS HW demands capabilities to assess onboard anomalies as well \
          as  the  effect  of  mitigating  actions  in  a  realistic  mission  setup  on  ground.  This  makes  the  system  a \
          versatile  mission  end-to-end  verification  and  validation  tool which will be tested by DLR.",
      },
      {
        imageUrl: "DLR_ESA_grey.png",
      },
    ],
    externalLinks: [
      {
        linkHeader: "Further Readings: ",
        linkURL: "http://www.egscc.esa.int/",
        linkText: "EGS-CC"
      },
      {
        linkURL: "http://www.eurosim.nl/",
        linkText: "EuroSim"
      },
    ],
  },
  {
    source: {
      id: "N000002",
      name: "GLEX CIS-SIM",
    },
    author: "thya",
    title: "GLEX 2021 paper: The CIS-SIM Facility",
    url: "glex-cis-sim",
    abstract:
      "This year at the GLEX 2021, we published an eager concept to support the development of ESA’S Lunar Communication and Navigation Service (LCNS) programme, which aims to catalyse upcoming lunar missions, including NASA’s Lunar Gateway, by offering a turnkey communication and  navigation service solution: 'CIS-SIM Facility: The Galileo-based European Navigation and Communication Constellation Simulator for the Cis-lunar Society'",
    imageUrl: "simulator_facility.jpg",
    imageCaption: "Artistic impression of the European CIS-SIM facility",
    publishedAt: "2021-07-27",
    content: [
      {
        contentText:
          "The LCNS shall be enabled by a lunar satellite constellation and offered to customers with spacecrafts in lunar orbit, lunar orbital descent and for lunar surface operations. To be able to develop, test and operate this lunar Navigation and Communication (navcom) infrastructure and the technology enabling and improving it, it is of importance to have a representative simulation environment as early as possible in the programme. Amongst others, such a simulation environment could be used to evaluate the navcom signals and mission concepts, to train operators and service customers, to troubleshoot anomalies of the deployed lunar infrastructure, to conduct test campaigns of orbital/surface mission scenarios (e.g for ESA’s Moon Village, Lunar Gateway, etc.), and to develop advanced Position Navigation and Timing (PNT) solutions (e.g. lunar Precise Point Positioning (PPP) corrections, terrestrial GNSS signal augmentation, etc.). We therefore suggest beginning with the development of the CIS-SIM Facility, representing a global first for a lunar navigation and communication constellation simulator, by reusing major components of flight-proven ground segment software of an active GNSS, namely Galileo. Its main purpose is to simulate the satellite constellation in the lunar orbit environment, to model the navcom signal propagation/distribution, and to allow simulated monitoring and control of the satellite platforms as well as their navcom payloads. Therefore, the design herein presented is a holistic and hybrid solution, being a navcom signal service volume simulator and a spacecrafts constellation simulator in one facility.",
      },
      {
        contentText:
          "The facility is a key infrastructure for the development of future cis-lunar navigation and communication technologies and lowers the barriers for their potential translunar application. It brings commercial and scientific space activities together and helps legacy and new market entrants to participate in the lunar industrialization process. Within this paper, we introduce to the overall topic and in particular to ESA’s LCNS programme. We elaborate on the CISSIM facility design with its core elements. We then showcase the potential for extensions by exemplary outlining three options and conclude the paper with the future outlook of the project.",
      },
      {
        contentText:
          "REFERENCE: K. Hoeflinger, S. Schneegans, A. Vazquez, P. Coutinho, A. Gerndt; CIS-SIM Facility: The Galileo-based European Navigation and Communication Constellation Simulator for the Cis-lunar Society; GLEX-2021; St.Petersburg, Russia; 2,1,7,x 62102",
      },
    ],
    externalLinks: [
      // {
      //   linkURL: "https://dl.iafastro.directory/",
      //   linkText: "Link to the paper database"
      // },
      {
        linkURL: "https://youtu.be/BQACOYyP47Y",
        linkText: "Link to Video Presentation"
      },
      {
        linkURL: "https://iafastro.directory/iac/paper/id/62102/abstract-pdf/GLEX-2021,2,1,7,x62102.brief.pdf?2021-02-28.23:29:44",
        linkText: "Link to the paper abstract"
      },
    ],
  },
  {
    source: {
      id: "N000001",
      name: "firstnews",
    },
    author: "thya",
    title: "thya aerospace launches",
    url: "thya-aerospace-launches",
    abstract:
      "after months of preparation, thya aerospace becomes operational. the legal establishment was conducted in June 2020, amidst the COVID crisis, teaching some important lessons for the upcoming way.",
    imageUrl: "office_2.jpg",
    imageCaption: "thya office room in plinganserstr. 150, munich",
    publishedAt: "2020-07-19",
    content: [
      {
        contentText:
          "thya aerospace got founded as a space technology solution provider without focusing a specific technology. we aim to design and integrate solutions from a software-driven perspective, working to become one of the most adaptable and reactive players on the international space market. the venture targets ground, user and space segment alike, trying to design solutions from a holistic perspective, blurring the lines among the individual segments. our daily work creates products which shall offer advantages and those advantages we try to transfer to our partners and customers.",
      },
      {
        contentText:
          "the new office is located in Munich, close to the river Isar with a wonderful view over the city. the venture is founded by an employee of the German Aerospace Center (DLR) and knowing its basis is the knowledge gathered there over all the years. there had been lessons learned on how to reach a desired goal within the expectations and lessons on how to burn up trying. both types of lesson are of importance and hopefully help to secure a future.",
      },
    ],
    externalLinks: [],
  },
];
