import React from "react";
// import sections
import Hero from "../components/sections/Hero";
const Home = () => {
  return (
    <>
      <Hero />
    </>
  );
};

export default Home;
