import React, { useRef, useEffect } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import ReactGA from "react-ga";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Views
import Home from "./views/Home";
import LegalNotice from "./views/LegalNotice";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Projects from "./views/Projects/Projects";
import Newsroom from "./views/Newsroom/Newsroom";
import Jobs from "./views/Jobs";
import Newspage from "./views/Newsroom/Newspage";

//project views
import PppProjectPage from "./views/Projects/PppProjectPage";
import SatsimulatorProjectPage from "./views/Projects/SatsimulatorProjectPage";

// Initialize Google Analytics
//ReactGA.initialize(process.env.REACT_APP_GA_CODE);
ReactGA.initialize("UA-192684342-1");

const trackPage = (page) => {
  ReactGA.set({ anonymizeIp: true });
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute
            exact
            path="/Newsroom"
            component={Newsroom}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/Newsroom/:newsId"
            component={Newspage}
            layout={LayoutDefault}
          />
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute
            exact
            path="/Projects"
            component={Projects}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/Projects/PPP"
            component={PppProjectPage}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/Projects/Satsimulator"
            component={SatsimulatorProjectPage}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/Jobs"
            component={Jobs}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/LegalNotice"
            component={LegalNotice}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/PrivacyPolicy"
            component={PrivacyPolicy}
            layout={LayoutDefault}
          />
        </Switch>
      )}
    />
  );
};

export default App;
