import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTilesJobs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  return (
    <div className="container-sm ">
      <div className={tilesClasses}>
        <div className="tiles-item reveal-from-bottom">
          <div className="tiles-item-inner">
            <div className="features-tiles-item-header">
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/icon_svg/feature-tile-icon-01.svg")}
                  width={64}
                  height={64}
                />
              </div>
            </div>
            <div className="features-tiles-item-content">
              <h4 className="mt-0 mb-8">team spirit</h4>
              <p className="m-0 text-sm">
                we value team spirit and support each other
              </p>
            </div>
          </div>
        </div>

        <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
          <div className="tiles-item-inner">
            <div className="features-tiles-item-header">
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/icon_svg/feature-tile-icon-03.svg")}
                  width={64}
                  height={64}
                />
              </div>
            </div>
            <div className="features-tiles-item-content">
              <h4 className="mt-0 mb-8">professional network</h4>
              <p className="m-0 text-sm">
                we help to enhance your professional network
              </p>
            </div>
          </div>
        </div>

        <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
          <div className="tiles-item-inner">
            <div className="features-tiles-item-header">
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/icon_svg/feature-tile-icon-05.svg")}
                  width={64}
                  height={64}
                />
              </div>
            </div>
            <div className="features-tiles-item-content">
              <h4 className="mt-0 mb-8">motivational projects</h4>
              <p className="m-0 text-sm">
                we are active in challenging and unique space projects
              </p>
            </div>
          </div>
        </div>
        <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
          <div className="tiles-item-inner">
            <div className="features-tiles-item-header">
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/icon_svg/feature-tile-icon-02.svg")}
                  width={64}
                  height={64}
                />
              </div>
            </div>
            <div className="features-tiles-item-content">
              <h4 className="mt-0 mb-8">workplace and equipment</h4>
              <p className="m-0 text-sm">
                we have a modern office with modern technology, needed to get it
                done
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FeaturesTilesJobs.propTypes = propTypes;
FeaturesTilesJobs.defaultProps = defaultProps;

export default FeaturesTilesJobs;
