import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/Logo";
import { NavLink } from "react-router-dom";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      window.removeEventListener("scroll", listenScrollEvent);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    //set the background primary when menue open
    if (select !== null) {
      select.classList.remove("transparent");
      select.classList.add("colored");
    }
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
    //check if you have to make it transerant again
    if (select !== null && window.scrollY < 60) {
      select.classList.remove("colored");
      select.classList.add("transparent");
    }
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    "site-header transparent",
    bottomOuterDivider && "has-bottom-divider",
    className
  );
  var select = document.querySelector(".site-header");

  const listenScrollEvent = (e) => {
    if (select !== null) {
      if (window.scrollY > 60) {
        select.classList.remove("transparent");
        select.classList.add("colored");
      } else {
        //make sure the menu is not open
        if (!isActive) {
          select.classList.remove("colored");
          select.classList.add("transparent");
        }
      }
    }
  };

  return (
    <header {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          <Logo onClick={closeMenu} />
          {!hideNav && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames("header-nav", isActive && "is-active")}
              >
                <nav
                  className={classNames(
                    "header-nav-inner ",
                    isActive && "is-active"
                  )}
                  onClick={closeMenu}
                >
                  <ul
                    className={classNames(
                      "list-reset text-sm text-color-black",
                      navPosition && `header-nav-${navPosition}`
                    )}
                  >
                    <li>
                      <NavLink
                        to="/Projects"
                        activeStyle={{ color: "#ffffff" }, { textDecoration: "underline" }}
                      >
                        our projects
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/Newsroom"
                        activeStyle={{ color: "#ffffff" }, { textDecoration: "underline" }}
                      >
                        newsroom
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/Jobs" activeStyle={{ color: "#ffffff" }, { textDecoration: "underline" }}>
                        jobs
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </nav>
            </>
          )}
        </div>
      </div>
    </header >
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
