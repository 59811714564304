export const jobsData = [
/* 
  {
    jobTitle: "Satellite System Software Engineer (m/f/d)",
    location: "Munich, Germany",
    employmentType: "Full-time",
    link:
      "/job_descriptions/thya_FT_satellite_system_software_developer.pdf",
  },
  {
    jobTitle: "Machine Learning based Satellite System Software Engineer (m/f/d)",
    location: "Munich, Germany",
    employmentType: "Full-time",
    link:
      "/job_descriptions/thya_FT_ai-based_satellite_system_software_developer.pdf",
  },
  {
    jobTitle: "Working Student : Machine Learning based Satellite System Software Engineer (m/f/d)",
    location: "Munich, Germany",
    employmentType: "Part-time",
    link:
      "/job_descriptions/thya_PT_ai-based_satellite_system_software_engineer.pdf",
  },*/
  {
    jobTitle: "initative applications",
    location: "Munich, Germany",
    employmentType: "full-time, part-time, thesis",
    link:
      "/job_descriptions/thya_intiative_applications.pdf",
  }
];
