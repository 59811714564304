import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "partners",
    paragraph:
      "space is a team sport, therefore we greatly value our partnerships",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-right "
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <Image
                  className="image-company-icons"
                  src={require("./../../assets/images/thya_and_partner_logos/DLR_logo_white.png")}
                />
                <div className="testimonial-item-content">
                  <p className="testimonial-item-content-bottom ta-c">
                    — we started from the German Aerospace Center (DLR). Our connections to the
                    institute for software technology are strong and we are cooperating in various fields.<br />

                  </p>
                </div>
                <div className="testimonial-item-footer mb-0">
                  <span className="testimonial-item-link">
                    <a href="https://www.dlr.de/sc/">www.dlr.de/sc</a>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom "
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <Image
                  className="image-company-icons"
                  src={require("./../../assets/images/thya_and_partner_logos/GFR_logo.png")}
                />
                <div className="testimonial-item-content ">
                  <p className="testimonial-item-content-bottom ta-c">
                    — the DLR Gesellschaft für Raumfahrtanwendungen is for us a
                    strong partner in GNSS applications and satellite operations.<br /> <br />
                  </p>
                </div>
                <div className="testimonial-item-footer mb-0">
                  <span className="testimonial-item-link mb-0">
                    <a href="https://www.dlr-gfr.com/en/">www.dlr-gfr.com</a>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom "
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <Image
                  className="image-company-icons"
                  src={require("./../../assets/images/thya_and_partner_logos/ESA_logo_white.png")}
                />
                <div className="testimonial-item-content ">
                  <p className="testimonial-item-content-bottom ta-c">
                    — the European Space Agency is a continuous partner, directly or indirectly in almost all our space projects and missions. <br /> <br />
                  </p>
                </div>
                <div className="testimonial-item-footer mb-0">
                  <span className="testimonial-item-link mb-0">
                    <a href="https://www.esa.int/">www.esa.int</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
