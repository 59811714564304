import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset mb-0 footer-nav-right">
        <li className="text-sm">
          <NavLink
            to
            onClick={() => {
              navigator.clipboard.writeText("contact@thya-aerospace.com");
              alert("Contact E-Mail is copied to your clipboard");
            }}
          >
            contact
          </NavLink>
        </li>
        <li className="text-sm">
          <NavLink to="/PrivacyPolicy" activeStyle={{ color: "#fab500" }}>
            privacy policy
          </NavLink>
        </li>
        <li className="text-sm">
          <NavLink to="/LegalNotice" activeStyle={{ color: "#fab500" }}>
            legal notice
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
