import React from "react";
import classNames from "classnames";
import Image from "../../components/elements/Image";
import Carousel from "react-bootstrap/Carousel";
import { newsData } from "../../components/data/News";
import { NavLink } from "react-router-dom";

const NewsSlide = (
  classsName,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
) => {
  const innerClasses = classNames(
    "section-inner container",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const newsTeaserText = classNames(
    " carousel-inner features-split-inner section-inner container",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <div className={innerClasses}>
      <Carousel className="reveal-rotate-from-left">
        {newsData.map((article, key) => {
          return (
            <Carousel.Item className="center-content round-border">
              <Image
                className="carousel-img"
                src={require(`./../../assets/images/news_images/${article.imageUrl}`)}
              />
              <NavLink to={`/Newsroom/${article.url}`}>
                <Carousel.Caption>
                  <div className={newsTeaserText}>
                    <div id="content-desktop">
                      <h3 className="news-article">{article.title}</h3>
                      <p className="news-abstract newsslide-text-limiter">
                        {" "}
                        {article.abstract}
                      </p>
                      <p className="text-color-mid text-xxs">
                        {article.author} - {article.publishedAt}
                      </p>{" "}
                    </div>
                  </div>
                  <div id="content-mobile">
                    {" "}
                    <p className="news-article">{article.title}</p>
                    <p className="text-color-mid  text-xxs pb-16">
                      {article.author} - {article.publishedAt}
                    </p>{" "}
                  </div>
                </Carousel.Caption>
              </NavLink>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default NewsSlide;
