import React from "react";
import classNames from "classnames";
import ProjectTiles from "../../components/sections/ProjectTiles";
import Contact from "../../components/sections/Contact";

const Projects = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
}) => {
  const innerClassesTop = classNames(
    "section-inner center-content image-top image-projects",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const innerClasses = classNames(
    "container-xsm container-top-box clip-folder",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section>
      <div className={innerClassesTop}>
        <div className={innerClasses}>
          <h1 className="reveal-from-bottom" data-reveal-delay="200">
            <span className="text-color-primary"> our space projects</span>
          </h1>
          <div className="ta-j reveal-from-bottom" data-reveal-delay="400">
            <span className="text-color-high">
              here we list the major space projects
              <span className="text-color-primary"> thya aerospace</span> is
              currently active in.
            </span>
          </div>
        </div>
      </div>
      <ProjectTiles />
      <Contact split />
    </section>
  );
};

export default Projects;
