import React from "react";
import classNames from "classnames";
import Image from "../../components/elements/Image";

const PppProjectPage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
}) => {
  const innerClassesTop = classNames(
    "section-inner center-content image-top image-projectPpp",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );
  const innerClassesTopDiv = classNames(
    "section-inner container ta-l mt-128",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );
  const innerClasses = classNames(
    "section-inner center-content container-sm",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section>
      <div className={innerClassesTop}>
        <div className={innerClassesTopDiv}>
          <h1 className="reveal-from-bottom" data-reveal-delay="200">
            <span className="text-color-primary">Precise Point Positioning</span>
          </h1>
          <h3 className="reveal-from-bottom" data-reveal-delay="200">
            <span className="text-color-primary">
              {" "}
              The High Accuracy Correction Service
            </span>
          </h3>
        </div>
      </div>
      <div className={innerClasses}>
        <div className="ta-j reveal-from-bottom " data-reveal-delay="400">
          <span className="text-color-high">
            {" "}
            <span className="text-color-primary"> Precise Point Positioning (PPP) </span>
            services provide real-time orbit and clock corrections as
              well as code and phase biases to achieve 20-centimeter level and better  GNSS
              position accuracy for the user. This uprising technology and
              world-wide available system enables a magnitude of applications,
              such as autonomous navigation, sensor geo-referencing and
              scientific geodesy
          </span>
        </div>
        <h3
          className="reveal-from-bottom text-color-primary ta-l"
          data-reveal-delay="200"
        >
          our involvement
        </h3>
        <div className="reveal-from-bottom ta-j">
          <span className="text-color-high">
            Within this project we support our partner gfr with software
            implementation of the PPP system. Our contributions range from
            system-level design and implementation, over sub-system
            implementation (i.e. monitoring and control) until anomaly detection
            and their trouble shooting. Within the PPP project we support
            our partner gfr with software implementation of the system. Our
            contributions range from system-level design and implementation,
            over sub-system implementation (i.e. monitoring and control) until
            anomaly detection and their trouble shooting.
          </span>
        </div>
        <h3 className="reveal-from-bottom ta-l" data-reveal-delay="200">
          <span className="text-color-primary "> PPP systems</span>
        </h3>
        <div className="reveal-from-bottom ta-j">
          <span className="text-color-high">
            PPP systems offer global absolute positioning, using GNSS constellation like the Galileo or the GPS.
            The system is capable of reaching centimeter level accuracy for the
            user by leveraging dual-frequency receivers and by the provision of
            real-time orbit and clock corrections as well as code and phase
            biases. Systems can support Galileo E1, E5a and E5b and GPS L1 and L2.
          </span>
          <Image
            src={require("./../../assets/images/project_images/navcast_diagram.svg")}
          />{" "}
          {/*      <Image
            style={{ padding: "30px" }}
            src={require("./../../assets/images/project_images/navcast_system.PNG")}
          />{" "} */}
          <span className="text-color-high">
            {" "}
            With PPP services, the position determination is absolute without
            additional raw measurements from local reference sta-tions (in
            contrast to differential GNSS services). Users can receive the RTCM 3 encoded corrections
            via the Internet using the NTRIP 2.0 protocol.
          </span>
        </div>
      </div>
    </section>
  );
};

export default PppProjectPage;
