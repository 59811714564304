import React from "react";
import classNames from "classnames";
import { newsData } from "../../components/data/News";
import Image from "../../components/elements/Image";
import { List } from "semantic-ui-react";
import { Container, Row, Col } from "reactstrap";
const Newspage = ({
  match,
  location,
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const innerClassesTop = classNames(
    "section-inner center-content newspage",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const contentClasses = classNames(
    "newspage-inner container-sm ta-l mt-32",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const ParagraphClasses = classNames("news-content ta-j mt-32 mb-32");
  const {
    params: { newsId },
  } = match;

  function ExternalLinks({ list, externalLinks, key }) {
    if (!externalLinks.linkURL || !externalLinks.linkText) {
      return;
    } else {
      if (!externalLinks.linkHeader) {
        return (
          <span className="testimonial-item-link mb-0">
            <a href={`${externalLinks.linkURL}`}>{externalLinks.linkText}</a><br />
          </span>
        );
      } else {
        return (
          <span className="testimonial-item-link mb-0">
            <p className="news-content">
              {externalLinks.linkHeader}<br />
            </p>
            <a href={`${externalLinks.linkURL}`}>{externalLinks.linkText}</a><br />
          </span>
        );
      }

    }
  }

  var flag;
  function MoreImage({ list, content, key }) {
    if (!content.imageUrl) {
      return <div className={ParagraphClasses}>{content.contentText}</div>;
    } else if (flag) {
      flag = !flag;
      return (
        <div className={ParagraphClasses}>
          <Container fluid>
            <Row
              style={{
                alignItems: "center",
              }}
            >
              <Col sm={6}>
                <p>{content.contentText}</p>
              </Col>
              <Col>
                <Image
                  src={require(`./../../assets/images/news_images/${content.imageUrl}`)}
                />
                <p className="text-xxs">{content.imageCaption}</p>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      flag = !flag;
      return (
        <div className={ParagraphClasses}>
          <Container fluid>
            <Row
              style={{
                alignItems: "center",
              }}
            >
              <Col>
                <Image
                  src={require(`./../../assets/images/news_images/${content.imageUrl}`)}
                />
                <p className="text-xxs">{content.imageCaption}</p>
              </Col>
              <Col sm={6}>
                <p> {content.contentText}</p>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  }
  const ArticleItemOnNewsPage = (props) => {
    const { article } = props;

    return (
      <div className={contentClasses} style={{ padding: 30 }}>
        <h3 className="news-article">{article.title} </h3>
        <List bulleted horizontal className="news-date-author">
          <List.Item>{article.author}</List.Item>
          <List.Item>{article.publishedAt.split("T")[0]}</List.Item>
        </List>
        <Image
          className="newspage-image-article"
          src={require(`./../../assets/images/news_images/${article.imageUrl}`)}
        />
        <p className="news-image-caption ta-j text-xxs">
          {article.imageCaption}
        </p>
        <p className="news-content ta-j">
          <strong>{article.abstract}</strong>
        </p>

        {article.content.map((content, key) => {
          return <MoreImage key={key} content={content} />;
        })}

        {article.externalLinks.map((externalLinks, key) => {
          return <ExternalLinks key={key} externalLinks={externalLinks} />;
        })}

      </div>
    );
  };
  const zeNews = newsData.filter((news) => news.url === newsId);
  return (
    <section {...props}>
      <div className={innerClassesTop}>
        <ArticleItemOnNewsPage article={zeNews[0]} />
      </div>
    </section>
  );
};

export default Newspage;
