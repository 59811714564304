import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
const PrivacyPolicy = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
}) => {
  const outerClasses = classNames(
    "center-content section-inner",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const bottomClasses = classNames(
    "section-middle ",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section className={outerClasses}>
      <div className={bottomClasses}>
        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
          <span className="text-color-primary">privacy policy</span>
        </h1>
        <div className="container-xs">
          <p
            className="m-0 mb-32 reveal-from-bottom ta-j"
            data-reveal-delay="400"
          >
            <h4
              className="mt-0 mb-16 reveal-from-bottom center-content"
              data-reveal-delay="200"
            >
              <br />
              1. General
            </h4>
            This data protection declaration is intended to inform the users of
            this website about the type, scope and purpose of the collection and
            use of personal data by the website operator thya aerospace GmbH.
            The website operator takes your data protection very seriously and
            treats your personal data confidentially and in accordance with
            legal regulations . As new technologies and the constant further
            development of this website can make changes to this data protection
            declaration, we recommend that you read the data protection
            declaration again at regular intervals. Definitions of the terms
            used (e.g. “personal data” or “processing”) can be found in Art. 4
            GDPR.
            <h4
              className="mt-0 mb-16 reveal-from-bottom center-content"
              data-reveal-delay="200"
            >
              <br />
              2. Webpage Access Data
            </h4>
            We, the website operator or website provider, collect data on access
            to the website based on our legitimate interest (see Art. 6 Para. 1
            lit. f. GDPR) and save it as "server log files" on the website
            server. The following data is logged: <br />
            <ul>
              <li>Visited website</li>
              <li>Time at the time of access</li>
              <li>Amount of data sent in bytes</li>
              <li>Source / reference from which you came to the page</li>
              <li>Used browser</li>
              <li>Used operating system</li>
              <li>Used IP address</li>
            </ul>
            The server log files are for a maximum Saved for 7 days and then
            deleted. The data is stored for security reasons, e.g. B. Abuse
            cases. If data have to be saved for reasons of evidence, they are
            excluded from deletion until the incident has been finally
            clarified.
            <h4
              className="mt-0 mb-16 reveal-from-bottom center-content"
              data-reveal-delay="200"
            >
              <br />
              3. Cookies
            </h4>
            This website uses cookies for pseudonymized range measurement,
            either from our server or the server of third parties to the user's
            browser. Cookies are small files that are stored on your device.
            Your browser accesses these files. The use of cookies increases the
            user-friendliness and security of this website. If you do not want
            cookies to be used to measure range, you can object to the use of
            these files here: <br />
            <ul>
              <li>
                Cookie deactivation page of the network advertising initiative:
                <a href="http://optout.networkadvertising.org/?c=1#!/">
                  http://optout.networkadvertising.org/?c=1#!/
                </a>
              </li>
              <li>
                Cookie deactivation page of US website:
                <a href="http://optout.aboutads.info/?c=2#!/">
                  http://optout.aboutads.info/?c=2#!/
                </a>
              </li>
              <li>
                Cookie deactivation page of European website:
                <a href="http://optout.networkadvertising.org/?c=1#!/">
                  http://optout.networkadvertising.org/?c=1#!/
                </a>
              </li>
            </ul>
            Common browsers offer the setting option to not allow cookies. Note:
            It is not guaranteed that you can access all functions of this
            website without restrictions if you make the appropriate settings.
            Collection and processing of personal data The website operator only
            collects, uses and passes on your personal data if this is permitted
            by law or you consent to the collection of data. Personal
            information is all information that serves to identify you and which
            can be traced back to you - for example your name, email address and
            telephone number. You can also visit this website, without providing
            personal information. However, in order to improve our online offer,
            we save your access data to this website (without personal
            reference). These access data include e.g. B. the file you requested
            or the name of your Internet provider. By anonymizing the data,
            conclusions about your person are not possible.
            <h5 className="mt-0 mb-8">
              Google Analytics
            </h5>
            This page  uses Google Analytics. This is a web analytics tool,
            offered by Google (inter alia Google Ireland Limited, Gordon House,
            Barrow Street, Dublin 4, Ireland and Google LLC, Mountain View,
            CA, USA) (“Google“). The google  analysis service is using “cookies”
            (elaborated above). Therefore, text files will be stored on your device.
            The information stored in these files about the usage of the thya aerospace
            website are generally transmitted and stored in Google servers in the USA.
            As the IP anonymization is active on this Website, your IP address will be
            shortened by Google within the member states of the European Union (EU).
            The IP address provided by your browser, as part of the Google Analytics
            does not get merged with other data from Google.
            <h4
              className="mt-0 mb-16 reveal-from-bottom center-content"
              data-reveal-delay="200"
            >
              <br />
              4. Personal Contact Data
            </h4>
            If you contact us as the website operator through the contact
            options offered, your details will be saved so that they can be used
            to process and answer your request. Without your consent, this data
            will not be passed on to third parties.
            <h4
              className="mt-0 mb-16 reveal-from-bottom  center-content"
              data-reveal-delay="200"
            >
              <br />
              5. User Rights
            </h4>
            As a user, you have the right to receive free information about the
            personal data about you that have been stored. You also have the
            right to correct incorrect data and to restrict the processing or
            deletion of your personal data. If applicable, you can also exercise
            your right to data portability. If you assume that your data has
            been processed illegally, you can lodge a complaint with the
            responsible supervisory authority.
            <h4
              className="mt-0 mb-16 reveal-from-bottom center-content"
              data-reveal-delay="200"
            >
              <br />
              6. Deletion of Data
            </h4>
            If your request does not conflict with a legal obligation to store
            data (e.g. data retention), you have the right to have your data
            deleted. Data stored by us will be deleted if they are no longer
            required for their intended purpose and there are no statutory
            retention periods. If deletion cannot be carried out because the
            data is required for permitted legal purposes, data processing is
            restricted. In this case, the data will be blocked and not processed
            for other purposes.
            <h4
              className="mt-0 mb-16 reveal-from-bottom center-content"
              data-reveal-delay="200"
            >
              <br />
              7. Right of Objection
            </h4>
            Users of this website can exercise their right to object and object
            to the processing of their personal data at any time. If you would
            like a correction, blocking, deletion or information about the
            personal data stored about you or have questions regarding the
            collection, processing or use of your personal data or would like to
            revoke your consent, please contact the following E-Mail address:{" "}
            <Link
              to="/PrivacyPolicy"
              onClick={() => {
                navigator.clipboard.writeText("contact@thya-aerospace.com");
                alert("Contact E-Mail is copied to your clipboard");
              }}
            >
              contact@thya-aerospace.com
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
