import React from "react";
import classNames from "classnames";
import Image from "../../components/elements/Image";

const SatsimulatorProjectPage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
}) => {
  const innerClassesTop = classNames(
    "section-inner center-content image-top image-projectSatSimulator",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );
  const innerClassesTopDiv = classNames(
    "section-inner container ta-l mt-128",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );
  const innerClasses = classNames(
    "section-inner center-content container-sm",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section>
      <div className={innerClassesTop}>
        <div className={innerClassesTopDiv}>
          <h1 className="reveal-from-bottom" data-reveal-delay="200">
            <span className="text-color-primary">integrated Space System Simulator</span>
          </h1>
        </div>
      </div>
      <div className={innerClasses}>
        <div className="ta-j reveal-from-bottom " data-reveal-delay="400">
          <span className="text-color-high">
            {" "}
            At thya aerospace we work at the development of an
            <span className="text-color-primary"> integrated space system simulator. </span>
            Those high fidelity simulators are a cornerstone for a successful space mission. They are an enabling technology for satellite system development, for assembly, integration and testing as well as for safe operation of the space segment in orbit. we further develop this key technology, integrating it in a modern development lifecycle.          </span>
        </div>
        <h3
          className="reveal-from-bottom text-color-primary ta-l"
          data-reveal-delay="200"
        >
          context process
        </h3>
        <div className="reveal-from-bottom ta-j">
          <span className="text-color-high">
            The space domain gradually changes their development approaches towards more agile processes, at least on the technical level of space missions. This paradigm change demands new ways on how to integrate development and verification tools in the mission lifecycle. With the former V-Model approach, there had been a single development direction, emphasizing the interfaces among mission phases. Agile approaches demand the possibility for development direction changes, implemented as iterative cycles. This approach can be executed without drawback in static projects but show their real advantage in projects with uncertain environments, with changing requirements. With the help of well-integrated high fidelity space system simulators, it is possible to execute the entire devops cycle, targeting software as well as hardware development. Important is that the devops lifecycle is not applied to its fullest on each phase of the mission, but gradually builds up while its repetitive executions.          </span>
          <Image
            src={require("./../../assets/images/project_images/simulator/dev_ops.svg")}
            width={750}
          />
        </div>
        <h3 className="reveal-from-bottom ta-l" data-reveal-delay="200">
          <span className="text-color-primary "> concept</span>
        </h3>
        <div className="reveal-from-bottom ta-j">
          <span className="text-color-high">
            the subsequent diagram displays the core components of our devops environment with the integrated space system simulator. The overall system is comprised of heterogenous and exchangeable components. we focus strongly on long-term sustainable data management, without creating a vendor or technology lock-in.
          </span>
          <Image
            src={require("./../../assets/images/project_images/simulator/dev_ops_concept.svg")}
            width={950}
          />
        </div>
      </div>
    </section>
  );
};

export default SatsimulatorProjectPage;
