import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import FeaturesSplit from "./FeaturesSplit";
import Testimonial from "./Testimonial";
import Cta from "./Cta";
import NewsSlide from "./../../views/Newsroom/NewsSlide";
const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const innerClassesTop = classNames(
    "section-inner center-content image-top image-home",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );
  const innerClasses = classNames(
    "center-content container-top-box container-xsm clip-folder-home",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <div>
      <section {...props}>
        <div className={innerClassesTop}>
          <div className={innerClasses}>
            <h1 className="reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-primary "> software for space</span>
            </h1>
            <div className="ta-j reveal-from-bottom" data-reveal-delay="400">
              <span className="text-color-primary">thya aerospace </span>
              <span className="text-color-high">
                {" "}
                is a software-driven space technology solution provider for
                user, ground and space segment.
              </span>
              <ButtonGroup className="mt-16 reveal-from-bottom header-social pb-8">
                <Button
                  className=" button-thyary"
                  tag="a"
                  color="primary"
                  wideMobile
                  href="https://www.linkedin.com/company/thya-aerospace/"
                >
                  connect on Linked
                  <a href="https://www.linkedin.com/company/thya-aerospace/">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Linkedin</title>
                      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                    </svg>
                  </a>
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </div>

        <NewsSlide />
        <FeaturesSplit invertMobile imageFill />
        <Testimonial />
        <Cta split />
      </section>
    </div>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
